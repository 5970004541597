import { useEffect, useState } from "react"

const useScreenWidth = () => {
  const [width, setWidth] = useState(0)

  const listener = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    setWidth(window.innerWidth)

    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return {
    width,
    showMobileMenu: width < 730,
  }
}

export default useScreenWidth
