import styled from 'styled-components'

const H1 = styled.h1`
  font-size: 34px;
  font-weight: 700;
  margin: 0;
  color: #1a1a41;
`

const H2 = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 27.5px;
  margin: 0;
  color: #1a1a41;
`

const H3 = styled.h3`
  font-size: 18px;
  font-weight: 500;
  line-height: 22.5px;
  margin: 0;
  color: #1a1a41;
`

const H4 = styled.h4`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  color: #1a1a41;
`

const Subtitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: #c7c8df;
`

const Default = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  color: #1a1a41;
`

const P = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  color: #1a1a41;
`

const Typography = {
  H1,
  H2,
  H3,
  H4,
  Subtitle,
  Default,
  P,
}

export default Typography
