import styled from 'styled-components'

import Dropdown from '../Dropdown'

export const ContentWrapper = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MobileDivider = styled.div<{ visible: boolean }>`
  left: 0;
  top: 80px;
  position: absolute;
  height: 1px;
  background: transparent;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 4;

  ${({ visible }) => (visible ? 'background-color: #C7C8DF;' : '')}
`

export const Link = styled.a`
  font-size: 16px;
  color: #1a1a41;
`

export const Links = styled.div`
  margin-left: 50px;

  ${Link}:not(:last-child) {
    margin-right: 15px;
  }
`

export const Side = styled.div`
  display: flex;
  align-items: center;
`

export const LanguageDropdown = styled(Dropdown)`
  margin: 0 15px;
`

export const MobileMenuOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  backdrop-filter: blur(3px);
  transition: 0.3s ease-in-out;

  ${({ visible }) =>
    !visible
      ? `
  opacity: 0;
  visibility: hidden;
  `
      : ''}
`

export const MobileMenuContainer = styled.div<{ visible: boolean }>`
  width: 300px;
  position: absolute;
  top: 80px;
  left: 0;
  height: 100vh;
  background-color: white;
  z-index: 3;
  padding: 15px;
  transition: 0.3s ease-in-out;
  transform: translateX(0);

  ${({ visible }) =>
    !visible
      ? `
    visibility: hidden;
    transform: translateX(-100%);
  `
      : ''}
`

export const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${Link}:not(:last-child) {
    margin-bottom: 15px;
  }
`

interface MobileSelectorOptionProps {
  active?: boolean
}

export const MobileSelectorOption = styled.button<MobileSelectorOptionProps>`
  padding: 0;
  background: none;
  border: none;
  font-famoly: Ubuntu, sans-serif;
  font-size: 16px;

  ${({ active }) => (active ? 'font-weight: 600;' : '')}

  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const MobileSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

export const MobileSelectorOptions = styled.div`
  display: flex;
  margin-top: 15px;
`

export const ToggleButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  position: relative;
`
