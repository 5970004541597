import React, { HTMLAttributes } from 'react'
import { TailSpin } from 'react-loader-spinner'

import { StyledButton } from './styled'

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  secondary?: boolean
  isLoading?: boolean
  className?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({ secondary, children, isLoading, ...rest }) => {
  return (
    <StyledButton secondary={secondary} disabled={isLoading} {...rest}>
      {isLoading ? <TailSpin height={25} width={25} color="white" /> : children}
    </StyledButton>
  )
}

export default Button
