import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const SelectedOption = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;

  span {
    margin-right: 5px;
  }
`

interface MenuProps {
  visible: boolean
}

export const Menu = styled.div<MenuProps>`
  min-width: 100%;
  position: absolute;
  background: white;
  transform: translateX(-50%) rotateX(20deg);
  left: 50%;
  top: 25px;
  transition: .2s ease-in-out;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  z-index: 5;

  ${({ visible }) => visible ? `
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%);
  ` : `
    opacity: 0;
    visibility: hidden;
  `}
`

export const MenuOption = styled.div`
  padding: 5px 0;
  cursor: pointer;
  text-align: center;
  
  &:hover {
    color: #4D5EFF;
  }
`
