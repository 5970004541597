import Image from 'next/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'

import {
  ContentWrapper,
  Links,
  Link,
  Side,
  LanguageDropdown,
  MobileMenuContainer,
  MobileMenuOverlay,
  MobileLinks,
  MobileSelectorOption,
  MobileSelectorContainer,
  MobileSelectorOptions,
  MobileDivider,
  ToggleButton,
} from './styled'
import closeIcon from '../../assets/Close.svg'
import menuIcon from '../../assets/Menu.svg'
import usePreferedCurrencyContext from '../../hooks/usePreferedCurrency'
import useScreenWidth from '../../hooks/useScreenWidth'
import Button from '../Button'
import Container from '../Container'
import Dropdown from '../Dropdown'
import Typography from '../Typography'

const localeOptions = [
  { id: 'en', label: 'EN' },
  { id: 'ru', label: 'RU' },
  { id: 'ka', label: 'KA' },
]

const currencyOptions = [
  { id: 'usd', label: 'USD' },
  { id: 'eur', label: 'EUR' },
  { id: 'gel', label: 'GEL' },
]

const MobileMenu = ({
  visible,
  close,
  hasReviews,
}: {
  visible: boolean
  close: () => void
  hasReviews: boolean
}) => {
  const router = useRouter()
  const { currency, changeCurrency } = usePreferedCurrencyContext()
  const { t } = useTranslation('common')

  const handleLocaleChange = (locale: string) => {
    router.replace(router.asPath, router.asPath, { locale })
  }

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'visible'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [visible])

  return (
    <>
      <MobileMenuOverlay visible={visible} />
      <MobileMenuContainer visible={visible}>
        <MobileLinks>
          <Link href="#cars" onClick={close}>
            {t('cars')}
          </Link>
          {hasReviews && (
            <Link href="#reviews" onClick={close}>
              {t('reviews')}
            </Link>
          )}
          <Link href="#faq" onClick={close}>
            {t('faq')}
          </Link>
          <NextLink href="/blog" passHref legacyBehavior>
            <Link>{t('blog')}</Link>
          </NextLink>
        </MobileLinks>
        <MobileSelectorContainer>
          <Typography.Default>{t('currency')}:</Typography.Default>
          <MobileSelectorOptions>
            {currencyOptions.map(({ id, label }) => (
              <MobileSelectorOption
                key={id}
                active={currency === id}
                onClick={() => changeCurrency(id)}
              >
                {label}
              </MobileSelectorOption>
            ))}
          </MobileSelectorOptions>
        </MobileSelectorContainer>
        <MobileSelectorContainer>
          <Typography.Default>{t('language')}:</Typography.Default>
          <MobileSelectorOptions>
            {localeOptions.map(({ id, label }) => (
              <MobileSelectorOption
                key={id}
                active={router?.locale === id}
                onClick={() => handleLocaleChange(id)}
              >
                {label}
              </MobileSelectorOption>
            ))}
          </MobileSelectorOptions>
        </MobileSelectorContainer>
        <Typography.H4>+995 599 901 489</Typography.H4>
      </MobileMenuContainer>
    </>
  )
}

const Header = ({
  simple,
  thin,
  medium,
  blog,
  hasReviews,
}: {
  simple?: boolean
  thin?: boolean
  medium?: boolean
  blog?: boolean
  hasReviews?: boolean
}) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { showMobileMenu } = useScreenWidth()
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  const { currency, changeCurrency } = usePreferedCurrencyContext()

  const handleLocaleChange = (locale: string) => {
    router.replace(router.asPath, router.asPath, { locale })
  }

  if (blog) {
    return (
      <Container medium>
        <ContentWrapper>
          <Side>
            <NextLink href="/blog" legacyBehavior>
              <a>
                <Typography.H2>DreamCar {t('blog')}</Typography.H2>
              </a>
            </NextLink>
          </Side>
          <Side>
            <NextLink href="/" passHref legacyBehavior>
              <Button secondary>{t('looking_for')}</Button>
            </NextLink>
          </Side>
        </ContentWrapper>
      </Container>
    )
  }

  if (simple) {
    return (
      <Container thin={thin} medium={medium}>
        <ContentWrapper>
          <Side>
            <NextLink href="/" legacyBehavior>
              <a>
                <Typography.H2>DreamCar</Typography.H2>
              </a>
            </NextLink>
          </Side>
          <Side>
            <Typography.H4>+995 599 901 489</Typography.H4>
            <LanguageDropdown
              selectedId={router.locale}
              options={localeOptions}
              onSelect={handleLocaleChange}
            />
          </Side>
        </ContentWrapper>
      </Container>
    )
  }

  return (
    <Container thin={thin} medium={medium}>
      <ContentWrapper>
        <Side>
          <NextLink href="/" legacyBehavior>
            <a>
              <Typography.H2>DreamCar</Typography.H2>
            </a>
          </NextLink>
          {!showMobileMenu && (
            <Links>
              <Link href="#cars">{t('cars')}</Link>
              {hasReviews && <Link href="#reviews">{t('reviews')}</Link>}
              <Link href="#faq">{t('faq')}</Link>
              <NextLink href="/blog" passHref legacyBehavior>
                <Link href="/blog">{t('blog')}</Link>
              </NextLink>
            </Links>
          )}
        </Side>
        {showMobileMenu ? (
          <ToggleButton onClick={() => setMobileMenuVisible((p) => !p)}>
            <Image fill src={mobileMenuVisible ? closeIcon.src : menuIcon.src} alt="Toggle menu" />
          </ToggleButton>
        ) : (
          <Side>
            <Typography.H3>+995 599 901 489</Typography.H3>
            <LanguageDropdown
              selectedId={router.locale}
              options={localeOptions}
              onSelect={handleLocaleChange}
            />
            <Dropdown selectedId={currency} options={currencyOptions} onSelect={changeCurrency} />
          </Side>
        )}
        <MobileDivider visible={mobileMenuVisible && showMobileMenu} />
      </ContentWrapper>
      {showMobileMenu && (
        <MobileMenu
          close={() => setMobileMenuVisible(false)}
          visible={mobileMenuVisible}
          hasReviews={hasReviews}
        />
      )}
    </Container>
  )
}

export default Header
