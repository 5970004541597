import styled from "styled-components"

interface StyledButtonProps {
  secondary?: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
  height: 45px;
  border-radius: 5px;
  width: 100%;
  color: white;
  background-color: #4d5eff;
  border: none;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #1b30fe;
  }

  ${({ disabled }) => disabled ? `
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  ` : ''}

  ${({ secondary }) =>
    secondary
      ? `
      color: #1A1A41;
    background-color: #FAFAFB;

    &:hover {
      background-color: #d0d0d9;
    }
  `
      : ""}
`
