import styled from "styled-components"

const Container = styled.div<{ thin?: boolean; medium?: boolean }>`
  width: 100%;
  max-width: ${({ thin, medium }) => (thin ? 550 : medium ? 1280 : 1470)}px;
  padding: 0 15px;
  margin: 0 auto;
`

export default Container
