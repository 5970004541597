import styled from 'styled-components'

import Container from '../Container'

export const FooterContainer = styled(Container)`
  padding-bottom: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ medium }) => (medium ? 'justify-content: center; margin-top: 24px;' : '')}

  @media (max-width: 883px) {
    flex-direction: column;
    padding-bottom: 7px;

    & > * {
      margin-bottom: 20px !important;
    }
  }
`

export const Links = styled.div`
  display: flex;
  color: #c7c8df;

  & > * {
    margin-right: 10px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }

  @media (max-width: 883px) {
    order: 3;
  }
`

export const Socials = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }

  @media (max-width: 883px) {
    order: 2;
  }
`
