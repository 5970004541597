import Image from 'next/image'
import React, { useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'

import { Container, Menu, MenuOption, SelectedOption } from './styled'
import dropdownArrow from '../../assets/DropdownArrow.svg'

interface DropdownProps {
  selectedId: string
  options: { id: string; label: string }[]
  onSelect?: (id: string) => void
  className?: string
}

const Dropdown: React.FC<DropdownProps> = ({ selectedId, options, onSelect, className }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const handleClickOutside = () => {
    setIsMenuVisible(false)
  }

  const ref = useDetectClickOutside({
    onTriggered: handleClickOutside,
  })

  const toggle = () => setIsMenuVisible((prev) => !prev)

  const filteredOptions = options.filter(({ id }) => id !== selectedId)
  const selectedOption = options.find(({ id }) => id === selectedId)

  const handleClick = (id: string) => {
    setIsMenuVisible(false)
    onSelect?.(id)
  }

  return (
    <Container ref={ref} className={className}>
      <SelectedOption onClick={toggle}>
        <span>{selectedOption?.label}</span>
        <Image {...dropdownArrow} alt="Arrow" />
      </SelectedOption>
      <Menu visible={isMenuVisible}>
        {filteredOptions.map((option) => (
          <MenuOption key={option.id} onClick={() => handleClick(option.id)}>
            {option.label}
          </MenuOption>
        ))}
      </Menu>
    </Container>
  )
}

export default Dropdown
