import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FooterContainer, Socials } from './styled'
import telegramIcon from '../../assets/Telegram.svg'
import viberIcon from '../../assets/Viber.svg'
import whatsIcon from '../../assets/Whatsapp.svg'
import Typography from '../Typography'

const Footer = ({ blog }: { blog?: boolean }) => {
  const { t } = useTranslation('common')

  return (
    <FooterContainer medium={blog}>
      <Typography.Default>© 2023 DreamCar | {t('all_rights')}</Typography.Default>
      {!blog && (
        <Socials>
          <Typography.Default>{t('send_us_message')}:</Typography.Default>
          <Image {...telegramIcon} alt="telegram" />
          <Image {...whatsIcon} alt="wa" />
          <Image {...viberIcon} alt="viber" />
          <a href="tel:+995599901489">+995 599 901 489</a>
        </Socials>
      )}
    </FooterContainer>
  )
}

export default Footer
